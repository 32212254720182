import React from "react"
import { Col, Container, Row } from "reactstrap"
import TitleBlock from "../components/blocks/TitleBlock"
import FeatureBlock from "../components/blocks/FeatureBlock"

import baselineMeetingRoom from "@iconify/icons-ic/baseline-meeting-room"
import bedIcon from "@iconify/icons-cil/bed"
import showerIcon from "@iconify/icons-cil/shower"
import outlineKitchen from "@iconify/icons-ic/outline-kitchen"
import userFriends from "@iconify/icons-fa-solid/user-friends"
import hairDryer from "@iconify/icons-mdi/hair-dryer"
import tableChair from "@iconify/icons-mdi/table-chair"
import tvOutline from "@iconify/icons-ion/tv-outline"
import babyIcon from "@iconify/icons-fa-solid/baby"
import wifiIcon from "@iconify/icons-mdi/wifi"
import highchairIcon from "@iconify/icons-vs/highchair"
import baselineLocalParking from "@iconify/icons-ic/baseline-local-parking"
import bedSingleOutline from "@iconify/icons-mdi/bed-single-outline"
import filePaper2Line from "@iconify/icons-ri/file-paper-2-line"
import Photos from "../components/general/Photos"
import ContactInfo from "../components/general/ContactInfo"
import SmallHeader from "../components/SmallHeader"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout.en"
import SEO from "../components/seo"

const Aparttament4Osobowy = props => {
  const images = useStaticQuery(graphql`
    {
      other: allFile(
        filter: {
          relativeDirectory: { eq: "rooms/4-persons" }
          name: { ne: "cover" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              sizes(maxWidth: 1800) {
                ...GatsbyImageSharpSizes_withWebp
              }
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      cover: file(
        name: { eq: "cover" }
        relativeDirectory: { eq: "rooms/4-persons" }
      ) {
        id
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout>
        <SEO title="Four-person room standard" lang="en" />

        <SmallHeader
          title="Four-person room standard"
          backgroundName={images.cover}
          hasButtonStyle={true}
          price="380-450PLN / night"
        />

        <Container>
          <Row>
            <Col md="6" className="mr-auto ml-auto">
              <div className="text-center section">
                <TitleBlock
                  title="Four-person room standard"
                  headingLevel="h3"
                  description="All our rooms have a kitchenette, a bathroom and a balcony from which there is a beautiful view of the sea and the lighthouse on the upper floors. Below you will find a detailed list of facilities and equipment for the selected apartment."
                  extendedClasses="color-sea"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="7" className="mr-auto ml-auto">
              <div className="section">
                <Row>
                  <Col md="6">
                    <FeatureBlock icon={userFriends} text="slips 4" />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={outlineKitchen}
                      text="kitchenette (induction hob, refrigerator, kettle, dishes)"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock icon={baselineMeetingRoom} text="2 rooms" />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={showerIcon}
                      text="bathroom with shower"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock
                      icon={bedIcon}
                      text="bedroom with two twin beds + double 2-person sofa bed in the living room"
                    />
                  </Col>
                  <Col md="6">
                    <FeatureBlock icon={hairDryer} text="hair drier" />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock
                      icon={tableChair}
                      text="balcony with table and chairs (some of the balconies on the upper floors have a view of the sea and lighthouses)"
                    />
                  </Col>
                  <Col md="6">
                    <FeatureBlock icon={filePaper2Line} text="guest towels" />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock
                      icon={tvOutline}
                      text="TV (about 130 available channels)"
                    />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={babyIcon}
                      text="baby's bed (requires reservation)"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock
                      icon={wifiIcon}
                      text="Wi-Fi Internet access"
                    />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={highchairIcon}
                      text="high chair (requires reservation)"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <FeatureBlock
                      icon={baselineLocalParking}
                      text="parking place (extra charge)"
                    />
                  </Col>
                  <Col md="6">
                    <FeatureBlock
                      icon={bedSingleOutline}
                      text="extra bed option (children over 4 years require additional charge)"
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md="10" className="mr-auto ml-auto">
              <div className="section">
                <Photos photos={images.other} />
              </div>

              <div className="section">
                <ContactInfo />
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Aparttament4Osobowy
